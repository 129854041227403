import React from "react";
const data = [
  {
    list: "A",
    title: "Data Protection",
    description: [
      "a. General Data Protection Regulation (GDPR) Compliance: Tomedes Ltd. is committed to safeguarding your privacy and ensuring compliance with the General Data Protection Regulation (GDPR) and other relevant data protection laws.",
      "b. Data Collection and Processing: By using our services and agreeing to these Terms of Service, you consent to the collection and processing of your personal data as described in our Privacy Policy. We may collect and process personal information in accordance with the provisions of the GDPR.",
    ],
  },
  {
    list: "B",
    title: "Data Controller",
    description: [
      "a. Data Controller: Tomedes acts as a Data Controller as defined by the GDPR. Our contact information for data protection inquiries is provided in our Privacy Policy.",
    ],
  },
  {
    list: "C",
    title: "Your Rights Under GDPR",
    description: [
      "a. Access and Correction: You have the right to access your personal data held by us and to request correction of any inaccurate information.",
      "b. Data Portability: You have the right to receive your personal data, which you have provided to us, in a structured, commonly used, and machine-readable format.",
      "c. Erasure: You may request the erasure of your personal data under certain circumstances, subject to legal obligations.",
      "d. Objection: You have the right to object to the processing of your personal data under certain circumstances.",
      "e. Restriction of Processing: You may request the restriction of processing of your personal data under certain circumstances.",
      "f. Withdrawal of Consent: If we process your personal data based on your consent, you have the right to withdraw your consent at any time.",
    ],
  },
  {
    list: "D",
    title: "Data Security",
    description: [
      "a. Data Security: We implement appropriate technical and organizational measures to ensure the security and confidentiality of your personal data. We regularly review and update our security measures to protect your data.",
    ],
  },
  {
    list: "E",
    title: "Data Transfers",
    description: [
      "a. International Data Transfers: Some of our data processing activities may involve the transfer of your personal data to countries outside the European Economic Area (EEA). We will ensure that any such transfers are conducted in compliance with applicable data protection laws, including the GDPR.",
    ],
  },
  {
    list: "F",
    title: "Contact Information",
    description: [
      "a. Data Protection Officer: For questions, concerns, or requests related to your personal data, including GDPR-related matters, please contact our Data Protection Officer: Jerica Fernes, talent@tomedes.com.",
    ],
  },
  {
    list: "G",
    title: "Changes to this Clause",
    description: [
      "a. Amendments: We may update this GDPR Compliance Clause to reflect changes in data protection laws or our data processing practices. Any changes will be posted on our website, and if required by law, we will seek your consent for any material changes.",
    ],
  },
  {
    list: "H",
    title: "Consent",
    description: [
      "a. Consent: By using our services, you consent to the terms outlined in this GDPR Compliance Clause and agree to abide by the requirements of the GDPR.",
    ],
  },
];

const TermGdpr = () => {
  return (
    <div>
      <h1 className="text-2xl font-primary font-bold "> GDPR Compliance</h1>
      {data.map((item, i) => (
        <ul
          key={i}
          className="rounded-lg mt-10 my-5 text-[#131313]   list-outside"
        >
          {item.list}. {item.title}
          {item.description.map((text) => (
            <li className="font-opensans leading-8 mt-5 ml-10">{text}</li>
          ))}
        </ul>
      ))}
    </div>
  );
};

export default TermGdpr;

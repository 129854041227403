import Layout from "components/layout";
import React from "react";
import Breadcrumb from "components/LegalPolicies/Breadcrumb";
import SEO from "components/seo";
import TermGdpr from "components/LegalPolicies/TermGdpr";
import backToTopIcon from "assets/backToTop.svg";

const data = [
  {
    title: "Copyright 2023 Tomedes Ltd. All rights reserved.",
    description: [
      "All materials and information found on the Tomedes website are protected by copyright. Unless expressly permitted herein, you may not reproduce any part of the materials or information on this website in any form or by any means without the explicit written consent of Tomedes.",
    ],
  },

  {
    title: "Content and Liability Disclaimer",
    description: [
      `Tomedes disclaims responsibility for any inaccuracies or omissions present on its website and reserves the right to modify content at any time without prior notice. References to non-Tomedes products or services are provided for informational purposes only and do not constitute an endorsement or recommendation by Tomedes. All information, whether from Tomedes or third parties, provided on the Tomedes website is presented on an "as is" basis.`,

      "TOMEDES DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, REGARDING ANY INFORMATION (INCLUDING SOFTWARE, PRODUCTS, OR SERVICES) PROVIDED ON ITS WEBSITE. THIS INCLUDES IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.",
      "Some jurisdictions do not permit the exclusion of implied warranties, so the above exclusion may not apply to you.",
      "Under no circumstances shall Tomedes be liable for any damages, including but not limited to direct, indirect, incidental, consequential, or special damages, or damages for lost profits, revenue, or data, resulting from the use or inability to use the Tomedes website, its products, or services. This applies even if Tomedes has been advised of the possibility of such damages.",
    ],
  },
  {
    title: "Release of Information for Legal Reasons",
    description: [
      "We may disclose information about your website usage when required by law or in good faith belief that such disclosure is necessary to protect our rights or comply with legal proceedings, court orders, or legal processes served on us or the website.",
    ],
  },
  {
    title: "Feedback Information",
    description: [
      "Any information provided to Tomedes through its website is non-confidential and shall be considered the property of Tomedes. By submitting such information, you agree to assign to Tomedes all worldwide rights, title, and interest in copyrights and other intellectual property rights to the information. Tomedes may use this information without restrictions.",
    ],
  },
  {
    title: "Links to Third-Party Sites",
    description: [
      "Tomedes' website may include links to third-party sites. Accessing external websites through links on the Tomedes website is done at your discretion. Tomedes is not responsible for the accuracy, reliability, or content of these external websites. Inclusion of third-party links does not imply endorsement or recommendation by Tomedes.",
    ],
  },
  {
    title: "Limitation of Liability",
    description: [
      "UNDER NO CIRCUMSTANCES SHALL TOMADES BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, OR OTHER DAMAGES ARISING FROM THE USE OF THIS WEBSITE, OR ANY HYPERLINKED WEBSITE, INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, BUSINESS INTERRUPTION, OR DATA LOSS, EVEN IF TOMADES HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.",
    ],
  },
  {
    title: "Security Policy",
    description: [
      "Respecting your privacy is crucial. We collect only the necessary data to enhance your website experience. The website employs security measures to protect against unauthorized access, alteration, or misuse of data under our control. Sensitive information submitted through the website is safeguarded online and offline using Secure Socket Layer (SSL) technology. While we adhere to industry standards, absolute security cannot be guaranteed. For more details, refer to our Privacy Statement regarding data collection and usage.",
    ],
  },
  {
    title: "Payment Partners",
    description: [
      "Tomedes utilizes Bluesnap and PayPal as its payment partners for processing transactions on this website. By using our services, you agree to abide by the terms and conditions of Bluesnap and PayPal, which govern the processing of payments, refunds, and disputes. You expressly authorize Tomedes to share necessary payment information with Bluesnap and PayPal for the purpose of completing transactions.",
      "Please note that Bluesnap and PayPal have their own terms of use and privacy policies, which you should review separately to understand their specific terms and practices. Tomedes is not responsible for the actions or policies of our payment partners, but we strive to work with reputable partners to ensure secure and convenient payment processing for our users.",
      "By using this website and its payment services, you acknowledge and agree to these terms and conditions related to our payment partners. ",
    ],
  },
  {
    title: "Refund & Cancellation Policy",
    description: [
      "1. Any cancellation request after an order has been processed must be submitted in writing to the following email address: support@tomedes.com",

      "2. If the company has not yet started the translation a full cancellation and refund will be granted. Should the translation already be in process, it will be stopped at the earliest opportunity (taking possible time zone hour differences into consideration). The client will only be charged for the part of the translation which has been completed.",

      "3. Our goal is to provide a service of exceptional quality at a great value. For this reason, we offer a 1-year guarantee on all of our language services. Should you have any concern or are not satisfied with the final product, please contact our customer service at support@tomedes.com or +1 985 239 0142. Our translator will review the translation according to the client’s comments and offer feedback. Any agreed upon errors will be edited at no extra charge to the client. Preferential changes are not included in the 1-year guarantee. In certain cases, where we fail to meet an agreement with the client, a partial or full refund may be offered. ",
    ],
  },
  {
    title: "Governing Law and Jurisdiction",
    description: [
      "Tomedes' website is operated from Holon, Israel. Your access and use of the website are governed by the laws of Israel. Any legal action arising from your use of the website shall be initiated in a court of law in Israel. You agree to the jurisdiction and venue of these courts.",
      "You must not use this website to access, download, or use information, including software, products, or services, in violation of Israeli export laws or regulations, or any applicable laws.",
    ],
  },
  {
    title: "Taxes",
    description: [
      "Tomedes does not collect taxes, duties, fees or other governmentally imposed excises, so User and Provider agree to assume their respective responsibilities under the law",
    ],
  },
  {
    title: "Trademarks",
    description: [
      `Trademarks, logos, and service marks ("Trademarks") appearing on the Tomedes website are the property of Tomedes Ltd. or respective parties. Use of any Trademark requires prior written permission from the owner.`,
    ],
  },
  {
    title: "Disclaimer - Force Majeure",
    description: [
      `In the event of unforeseen circumstances beyond our control, Tomedes may not be liable for delays or inability to perform our services. Such circumstances may include, but are not limited to, acts of nature, pandemics, wars, or government actions.`,
    ],
  },
];

function TermsOfService() {
  return (
    <>
      <SEO
        title="Terms of Service - Tomedes"
        description=" Review Tomedes' Terms of Service for guidelines on using our services, user rights, copyrights, and liabilities. Stay informed for a seamless experience."
        keywords=""
        slug="/legal/terms-of-service"
      />
      <Layout>
        <div className="max-w-7xl p-4 mx-auto space-y-8 py-12">
          <Breadcrumb path={["Legal Policies", "Terms of Service"]} />
          <div>
            <h1 className="text-5xl font-bold">
              Terms of Service - Tomedes Ltd.
            </h1>
            <p className="font-opensans mt-4">
              Effective as of September 21, 2023
            </p>
          </div>
          <div className="space-y-3">
            <p className="font-opensans leading-8">
              Please review these terms and conditions attentively. By accessing
              and using this website, you signify your agreement to these terms
              and conditions. Should you disagree with these terms and
              conditions, kindly refrain from using this website. Note that
              these terms and conditions, as well as the website's content, are
              subject to modification without prior notice.
            </p>
          </div>
          <div className="flex flex-col mt-[60px] gap-8">
            {data.map((item, i) => (
              <div key={i} className="rounded-lg mt-10 my-5 text-[#131313]">
                <h1 className="text-2xl font-primary font-bold ">
                  {item.title}
                </h1>

                {item.description.map((text) => (
                  <p className="font-opensans leading-8 mt-5 ">{text}</p>
                ))}
              </div>
            ))}
          </div>

          <div className="rounded-lg mt-10 my-5 text-[#131313]">
            <h1 className="text-2xl font-primary font-bold leading-9 ">
              Digital Millennium Copyright Act ("DMCA")
            </h1>

            <p className="font-opensans leading-8 mt-5 ">
              If you believe that content on the Tomedes website infringes upon
              your copyright, you may submit a notification of claimed
              infringement under the Digital Millennium Copyright Act ("DMCA")
              including:
            </p>
            <ul className="mt-5 list-disc pl-[20px] flex flex-col gap-5">
              <li className="ml-5">
                Signature of an authorized person representing the copyright
                owner.
              </li>
              <li className="ml-5">
                Identification of the copyrighted work or works allegedly
                infringed.
              </li>
              <li className="ml-5">
                Description of the material to be removed or disabled.
              </li>
              <li className="ml-5">Contact information.</li>
              <li className="ml-5">Statement of good faith belief.</li>
              <li className="ml-5">
                Statement of accuracy, made under penalty of perjury.
              </li>
            </ul>
            <p className="font-opensans leading-8 mt-5 ">
              Our Designated Copyright Agent for DMCA notices is:
            </p>
            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Tomedes Ltd.</span>
              <span>26 HaRokmim st. Azrieli Business Center,</span>
              <span>Building C, 7th floor, Holon 5885849</span>
              <span>Israel</span>
              <span>By telephone: +972 (0)72 220 0700</span>
              <span>By email: support@tomedes.com</span>
            </div>
            <p className="font-opensans leading-8 mt-5 ">
              Failure to comply with the requirements may invalidate your DMCA
              notice.
            </p>
          </div>
          <TermGdpr />
          <div className="flex flex-col items-center mt-[50px]">
            <img
              src={backToTopIcon}
              alt="backIcon"
              className="cursor-pointer"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
            <span className="mt-4 font-primary">Back To Top</span>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default TermsOfService;
